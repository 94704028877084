import React, { useContext, useState } from "react";
import { useQuery, useMutation } from "react-apollo-hooks";
import { observer } from "mobx-react-lite";
import { StoreContext } from "../store";
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle, 
  FormControlLabel, 
  Checkbox, 
  Box, 
  Typography, 
} from "@material-ui/core";
import { Info as InfoIcon } from "@material-ui/icons";
import gql from "graphql-tag";
import SavedSnack from "./SavedSnack";
import "../css/AddAttendances.css";

const query = gql`
  query getStudentAttendances($turma: Int, $email: String!) {
    lesson(
      where: {
        turma: { _eq: $turma }
        deleted_at: { _is_null: true }
        number: { _gte: 0.1, _lt: 99 }
      }
      order_by: { number: asc }
    ) {
      id
      number
      start_date
      title
      live_attendances(where: { personByPerson: { email: { _eq: $email } } }) {
        id
      }
    }
    live_schedule(
      where: { turma: { _eq: $turma }, deleted_at: { _is_null: true } }
    ) {
      day
    }
    person(where: { email: { _eq: $email } }) {
      id
    }
  }
`;

const mutation = gql`
  mutation upsert($objects: [live_attendance_insert_input!]!) {
    insert_live_attendance(objects: $objects) {
      affected_rows
    }
  }
`;

const StudentAttendance = observer(() => {
  const store = useContext(StoreContext);
  const [snack, setSnack] = useState({ msg: "", open: false });
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [confirmCheckbox, setConfirmCheckbox] = useState(false);
  const [processingRegistration, setProcessingRegistration] = useState(false);

  const showMsg = (msg) => {
    setSnack({ msg: msg, open: true });
  };

  const [update] = useMutation(mutation);

  const turma = store.ui?.turma?.id;
  const email = store.email;

  if (!turma || !email) {
    return <p>Carregando informações...</p>;
  }

  const { data, error, loading } = useQuery(query, {
    variables: { turma: turma, email: email },
  });

  if (loading) return <p>Carregando...</p>;
  if (error) return <p>Erro: {error.message}</p>;
  if (!data || !data.lesson) return <p>Nenhum dado encontrado</p>;

  data.lesson.forEach((lesson) => {
    var dates = [];
    if (lesson.title.toUpperCase().includes("WORKSHOP")) {
      let workshopDate = new Date(lesson.start_date);
      lesson.actualDates = [workshopDate];
      dates.push(
        workshopDate.toLocaleDateString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
        })
      );
    } else {
      let baseDate = new Date(lesson.start_date);

      let firstClass = new Date(baseDate);
      firstClass.setDate(firstClass.getDate() + 6);

      let secondClass = new Date(baseDate);
      secondClass.setDate(secondClass.getDate() + 7);

      lesson.actualDates = [firstClass, secondClass];

      dates.push(
        firstClass.toLocaleDateString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
        })
      );
      dates.push(
        secondClass.toLocaleDateString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
        })
      );
    }
    lesson.dates = dates.join(" ou ");
  });

  const openConfirmDialog = (lesson) => {
    setSelectedLesson(lesson);
    setConfirmCheckbox(false);
    setConfirmDialogOpen(true);
  };

  const handleConfirmPresenca = async () => {
    if (!selectedLesson || !confirmCheckbox) return;
    
    if (selectedLesson.live_attendances.length) {
      showMsg("Presença já registrada para esta lição");
      setConfirmDialogOpen(false);
      return;
    }

    setProcessingRegistration(true);

    try {
      await update({
        variables: {
          objects: [
            {
              person: data.person[0]?.id,
              lesson: selectedLesson.id,
              date: selectedLesson.start_date,
            },
          ],
        },
      });
      showMsg("Presença registrada com sucesso!");
      setConfirmDialogOpen(false);
      window.location.reload();
    } catch (e) {
      showMsg(e.message);
    } finally {
      setProcessingRegistration(false);
    }
  };

  const isLessonAvailable = (lesson) => {
    if (lesson.live_attendances.length) return false;
    if (!lesson.actualDates) return false;

    const now = new Date();
    now.setHours(0, 0, 0, 0);

    return lesson.actualDates.some((lessonDate) => {
      const date = new Date(lessonDate);
      date.setHours(0, 0, 0, 0);
      return date <= now;
    });
  };

  const shouldShowLesson = (lesson) => {
    if (!lesson.actualDates || !lesson.actualDates.length) return false;
    const now = new Date();
    const firstClassDate = new Date(lesson.actualDates[0]);
    now.setHours(0, 0, 0, 0);
    firstClassDate.setHours(0, 0, 0, 0);
    return firstClassDate <= now;
  };

  return (
    <>
      <h2 style={{ textAlign: "center", marginBottom: "10px" }}>
        Registre sua presença nas aulas
      </h2>
      <p style={{ textAlign: "center", marginBottom: "20px" }}>
        Clique no botão de cada lição para registrar sua presença.
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          maxWidth: "400px",
          margin: "0 auto",
        }}
      >
        {data.lesson
          .sort((a, b) => a.number - b.number)
          .filter(shouldShowLesson)
          .map((lesson) => (
            <Button
              key={lesson.id}
              variant="contained"
              color={lesson.live_attendances.length ? "default" : "primary"}
              onClick={() => isLessonAvailable(lesson) ? openConfirmDialog(lesson) : null}
              disabled={!isLessonAvailable(lesson)}
              style={{
                justifyContent: "flex-start",
                padding: "15px",
                width: "100%",
              }}
            >
              Lição {lesson.number} - {lesson.dates}
              {lesson.live_attendances.length
                ? " (Presença registrada)"
                : !isLessonAvailable(lesson)
                ? " (Disponível apenas no dia da aula)"
                : ""}
            </Button>
          ))}
        {data.lesson.filter(shouldShowLesson).length === 0 && (
          <div
            style={{
              textAlign: "center",
              padding: "20px",
              backgroundColor: "#f5f5f5",
              borderRadius: "4px",
              color: "#666",
            }}
          >
            Nenhuma lição disponível no momento. As lições serão exibidas nas
            datas das aulas ao vivo.
          </div>
        )}
      </div>

      <Dialog
        open={confirmDialogOpen}
        onClose={() => !processingRegistration && setConfirmDialogOpen(false)}
        aria-labelledby="confirm-attendance-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="confirm-attendance-dialog-title">
          Confirmar Registro de Presença
        </DialogTitle>
        <DialogContent>
          <Box 
            mb={2} 
            p={2} 
            bgcolor="#e3f2fd" 
            borderRadius={4} 
            display="flex" 
            alignItems="flex-start"
          >
            <InfoIcon style={{ color: '#2196f3', marginRight: 12, marginTop: 2 }} />
            <DialogContentText>
              Você está prestes a registrar presença para a Lição {selectedLesson?.number}. 
              Esta ação confirmará que você participou desta aula.
            </DialogContentText>
          </Box>
          
          <DialogContentText style={{ marginBottom: 16 }}>
            Por favor, confirme que você realmente participou desta aula e está ciente de que:
          </DialogContentText>
          
          <Box mb={2}>
            <Typography variant="body2" style={{ marginBottom: 8 }}>
              • O registro de presença é uma declaração oficial de participação
            </Typography>
            <Typography variant="body2">
              • Este registro não pode ser desfeito posteriormente
            </Typography>
          </Box>
          
          <FormControlLabel
            control={
              <Checkbox
                checked={confirmCheckbox}
                onChange={(e) => setConfirmCheckbox(e.target.checked)}
                color="primary"
              />
            }
            label="Confirmo que participei desta aula e estou ciente das condições acima"
            style={{ marginTop: 16 }}
          />
        </DialogContent>
        <DialogActions style={{ padding: 16 }}>
          <Button 
            onClick={() => setConfirmDialogOpen(false)} 
            color="default"
            disabled={processingRegistration}
          >
            Cancelar
          </Button>
          <Button 
            onClick={handleConfirmPresenca} 
            color="primary" 
            variant="contained"
            disabled={!confirmCheckbox || processingRegistration}
          >
            {processingRegistration ? "Processando..." : "Confirmar Presença"}
          </Button>
        </DialogActions>
      </Dialog>

      <SavedSnack
        open={snack.open}
        msg={snack.msg}
        setOpen={(o) => setSnack({ ...snack, open: o })}
      />
    </>
  );
});

export default StudentAttendance;
