import React, { useContext } from "react";
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import { StoreContext } from "../store";
import { observer } from "mobx-react-lite";
import MUIDataTable from "mui-datatables";
import { Button } from "@material-ui/core";
import "../css/Grades.css";

const QUERY_MODULAR_ATTENDANCES = gql`
  query getModularAttempts($email: String, $turma: Int) {
    turma(where: { id: { _eq: $turma }, deleted_at: { _is_null: true } }) {
      id
      registers {
        is_professor
        is_tutor
        is_team_member
        is_monitor
        is_audit
        is_evaluator
        suspended
        cancelled
        locked
        dependency
        enrollmentByEnrollment {
          person {
            id
            first_name
            last_name
            email
          }
        }
      }
    }
    disciplina(
      where: {
        lessons: { turma: { _eq: $turma } }
        deleted_at: { _is_null: true }
      }
      order_by: { id: asc }
    ) {
      id
      name
      lessons(
        where: {
          turma: { _eq: $turma }
          deleted_at: { _is_null: true }
          number: { _gte: 0.1, _lt: 99 }
        }
        order_by: { number: asc }
      ) {
        id
        number
        title
        activities(
          where: { available: { _eq: true }, deleted_at: { _is_null: true } }
        ) {
          id
          type
          activity_completions(
            where: {
              personByPerson: {
                email: { _eq: $email }
                deleted_at: { _is_null: true }
              }
              completed: { _eq: true }
            }
          ) {
            id
            personByPerson {
              id
              first_name
              last_name
            }
          }
        }
      }
    }
    
    modular_class_schedule(
      where: { 
        turma_id: { _eq: $turma }
      }
      order_by: { class_date: asc }
    ) {
      id
      class_date
    }
    
    all_modular_live_attendance: modular_live_attendance(
      where: { 
        turma_id: { _eq: $turma }
      }
    ) {
      id
      person_id
      class_date
      person {
        id
        first_name
        last_name
        email
      }
    }
    
    personal_modular_attendance: modular_live_attendance(
      where: { 
        turma_id: { _eq: $turma },
        person: { email: { _eq: $email } }
      }
    ) {
      id
      class_date
      person_id
    }
  }
`;

const MODULAR_CONFIG = {
  "Especialização FAP": {
    ignore: [
      "Aula 2 - parte 1",
      "Aula 2 - parte 2",
      "Não obrigatória",
      "Extra",
    ],
    quizanalysis: true,
    classes: ["Autoavaliação"],
  },
  "Formação FAP": {
    ignore: ["Aula 2 - parte 1", "Aula 2 - parte 2"],
    quizanalysis: true,
    classes: ["Autoavaliação"],
  },
  "Especialização ACT": {
    classes: ["Autoavaliação"],
    quizanalysis: true,
  },
  "Formação ACT": {
    classes: ["Autoavaliação"],
    quizanalysis: true,
  },
  "Curso Completo RFT": {
    quizanalysis: true,
  },
  "Módulo 1 RFT": {
    quizanalysis: true,
  },
  "Fundamentos da FAP": {
    classes: ["Autoavaliação"],
    quizanalysis: true,
  }
};

const MIN_ATTENDANCE_PERCENTAGE = 0.75;

const getStudentTitles = (registers, store) => {
  const titles = {};
  if (store.admin || store.is_monitor) {
    registers.forEach(register => {
      if (register?.enrollmentByEnrollment?.person) {
        const name = `${register.enrollmentByEnrollment.person.first_name || ''} ${register.enrollmentByEnrollment.person.last_name || ''}`;
        const status = [
          register.suspended && "SUSPENSO",
          register.cancelled && "CANCELADO",
          register.locked && "Matrícula trancada",
          register.dependency && "fazendo dependência",
          register.is_tutor && "Tutor",
          register.is_monitor && "Monitor",
          register.is_professor && "Professor",
          register.is_team_member && "Equipe",
          register.is_audit && "Ouvinte",
          register.is_evaluator && "Avaliador",
        ].filter(Boolean);
        
        titles[name] = status.length > 0 ? ` (${status.join(", ")})` : "";
      }
    });
  }
  return titles;
};

const processActivityAttendanceData = (data, classesList, ignoreList) => {
  const attendances = [];
  const disciplines = [];
  const total_activities = {};
  const allStudents = new Map(); 

  classesList.forEach(className => {
    disciplines.push(className);
    total_activities[className] = 0;
  });

  data.turma[0].registers.forEach(register => {
    if (register?.enrollmentByEnrollment?.person) {
      const student = register.enrollmentByEnrollment.person;
      const studentName = `${student.first_name || ''} ${student.last_name || ''}`;
      if (!allStudents.has(studentName)) {
        allStudents.set(studentName, true);
        attendances.push({ nome: studentName });
      }
    }
  });

  data.disciplina.forEach(disciplina => {
    let hasValidActivities = false;
    
    disciplina.lessons.forEach(lesson => {
      if (lesson.number < 0.1) return;
      lesson.activities.forEach(activity => {
        if (!ignoreList.includes(activity.type)) {
          hasValidActivities = true;
        }
      });
    });

    if (hasValidActivities) {
      disciplines.push(disciplina.name);
      total_activities[disciplina.name] = 0;
    }

    disciplina.lessons.forEach(lesson => {
      if (lesson.number < 0.1) return;

      lesson.activities.forEach(activity => {
        if (!ignoreList.includes(activity.type)) {
          const category = classesList.includes(activity.type)
            ? activity.type
            : disciplina.name;

          total_activities[category]++;

          activity.activity_completions.forEach(completion => {
            if (completion?.personByPerson) {
              const studentName = `${completion.personByPerson.first_name || ''} ${completion.personByPerson.last_name || ''}`;
              
              let studentIndex = attendances.findIndex(a => a.nome === studentName);
              if (studentIndex === -1) {
                studentIndex = attendances.length;
                attendances.push({ nome: studentName });
              }

              if (!attendances[studentIndex][category]) {
                attendances[studentIndex][category] = 1;
              } else {
                attendances[studentIndex][category]++;
              }
            }
          });
        }
      });
    });
  });

  attendances.forEach(student => {
    disciplines.forEach(discipline => {
      if (student[discipline] === undefined) {
        student[discipline] = 0;
      }
    });
  });

  return { attendances, disciplines, total_activities };
};

const processModularLiveAttendanceData = (data, attendances, disciplines, total_activities) => {
  if (!disciplines.includes("Aula ao vivo")) {
    disciplines.push("Aula ao vivo");
  }
  
  total_activities["Aula ao vivo"] = (data.modular_class_schedule || []).length;
  
  attendances.forEach(student => {
    if (student["Aula ao vivo"] === undefined) {
      student["Aula ao vivo"] = 0;
    }
  });
  
  if (total_activities["Aula ao vivo"] === 0) {
    return { attendances, disciplines, total_activities };
  }
  
  const attendanceByStudent = {};
  
  data.all_modular_live_attendance?.forEach(attendance => {
    if (attendance?.person) {
      const studentName = `${attendance.person.first_name || ''} ${attendance.person.last_name || ''}`;
      
      if (!attendanceByStudent[studentName]) {
        attendanceByStudent[studentName] = 0;
      }
      
      attendanceByStudent[studentName]++;
    }
  });
  
  Object.entries(attendanceByStudent).forEach(([studentName, count]) => {
    let studentIndex = attendances.findIndex(a => a.nome === studentName);
    
    if (studentIndex === -1) {
      studentIndex = attendances.length;
      attendances.push({ 
        nome: studentName,
        "Aula ao vivo": 0
      });
    }
    
    attendances[studentIndex]["Aula ao vivo"] = count;
  });

  return { attendances, disciplines, total_activities };
};

const buildTableColumns = (disciplines, total_activities, titles, store) => {
  const columns = [
    {
      name: "nome",
      label: "Nome",
      options: {
        customBodyRender: (value) => value + (titles[value] || ""),
      }
    }
  ];

  disciplines.forEach(discipline => {
    columns.push({
      name: discipline,
      label: `${discipline} (${total_activities[discipline]})`,
      options: {
        customBodyRender: (value) => {
          const color = (value || 0) < total_activities[discipline] * MIN_ATTENDANCE_PERCENTAGE 
            ? "#cf7303" 
            : "black";
          return (
            <div style={{ color, fontWeight: "bold" }}>
              {value || 0}
            </div>
          );
        }
      }
    });
  });

  if (store.admin) {
    columns.push({
      name: "Resultado",
      options: {
        customBodyRender: (value) => (
          <div style={{ 
            color: value === "Reprovado" ? "red" : "black",
            fontWeight: "bold"
          }}>
            {value}
          </div>
        )
      }
    });
  }

  return columns;
};

const ModularStudentView = ({ studentData, disciplines, total_activities, history }) => {
  
  const studentDataCopy = { ...studentData };
  delete studentDataCopy.nome;
  
  const individualAttendances = disciplines.map(discipline => ({
    atividade: `${discipline} (${total_activities[discipline]})`,
    frequencia: studentDataCopy[discipline] || 0
  }));

  return (
    <div style={{ display: "table", tableLayout: "fixed", width: "100%" }}>
      <Button
        style={{ marginBottom: "10px" }}
        variant="contained"
        color="primary"
        onClick={() => history.push("/modular-self-attendance")}
      >
        MARCAR PRESENÇA EM AULA AO VIVO
      </Button>
      
      <MUIDataTable
        data={individualAttendances}
        columns={[
          { name: "atividade", label: "Atividade (total)" },
          {
            name: "frequencia",
            label: "Frequência",
            options: {
              customBodyRender: (value, tableMeta) => {
                const activityName = tableMeta.rowData[0].split(" (")[0];
                const total = total_activities[activityName];
                
                const color = value < total * MIN_ATTENDANCE_PERCENTAGE ? "#cf7303" : "black";
                return (
                  <div style={{ color, fontWeight: "bold" }}>
                    {value}
                  </div>
                );
              }
            }
          }
        ]}
        title={"Frequências"}
        options={{
          responsive: "scroll",
          selectableRows: false,
          filterType: "multiselect",
        }}
      />
    </div>
  );
};

const ModularAttendances = observer((props) => {
  const store = useContext(StoreContext);
  const variables = { 
    turma: store.ui.turma.id,
    ...(!(store.admin || store.is_monitor) && { email: props.email })
  };

  const { data, error, loading } = useQuery(QUERY_MODULAR_ATTENDANCES, { variables });

  if (loading) return <p>Carregando...</p>;
  if (error) return <p>Erro: {JSON.stringify(error)}</p>;
  if (data.turma.length === 0) return <p>Turma não encontrada.</p>;

  const courseConfig = MODULAR_CONFIG[store.ui.turma.course] || {};
  const ignoreList = courseConfig.ignore || [];
  const classesList = courseConfig.classes || [];

  const { attendances, disciplines, total_activities } = processActivityAttendanceData(
    data,
    classesList,
    ignoreList
  );

  processModularLiveAttendanceData(
    data, 
    attendances,
    disciplines, 
    total_activities
  );

  const titles = getStudentTitles(data.turma[0].registers, store);

  if (store.admin) {
    attendances.forEach(student => {
      let passed = true;
      disciplines.forEach(disc => {
        const total = total_activities[disc];
        const attended = student[disc] || 0;
        passed = passed && (attended >= total * MIN_ATTENDANCE_PERCENTAGE);
      });
      student.Resultado = passed ? "Aprovado" : "Reprovado";
    });
  }

  const columns = buildTableColumns(
    disciplines, 
    total_activities, 
    titles, 
    store
  );

  if (!store.admin && attendances.length > 0) {
    return (
      <ModularStudentView
        studentData={attendances[0]}
        disciplines={disciplines}
        total_activities={total_activities}
        history={props.history}
      />
    );
  }

  return (
    <div style={{ display: "table", tableLayout: "fixed", width: "100%" }}>
      <MUIDataTable
        data={attendances}
        columns={columns}
        title="Frequências do Curso Modular"
        options={{
          responsive: "scroll",
          rowsPerPageOptions: [10, 50, 200],
          rowsPerPage: 200,
          selectableRows: false,
          filterType: "multiselect",
          sortOrder: {
            name: "nome",
            direction: "asc"
          }
        }}
      />
    </div>
  );
});

export default ModularAttendances; 