/* eslint-disable no-redeclare */
/* eslint-disable array-callback-return */
/* eslint-disable no-unreachable */
import React, { useState, useContext } from "react";
import { useQuery, useMutation } from "react-apollo-hooks";
import { Formik, Field, Form, FieldArray } from "formik";
import {
  Button,
  IconButton,
  Input,
  Checkbox,
  Dialog,
  NativeSelect,
  InputLabel,
} from "@material-ui/core";
import { TextField } from "formik-material-ui";
import Paper from "@material-ui/core/Paper";
import gql from "graphql-tag";
import { StoreContext } from "../../store";
import { observer } from "mobx-react-lite";
import SavedSnack from "../SavedSnack";
import axios from "axios";
import InvoiceTable from "./InvoiceTable";
import HighlightOffTwoToneIcon from "@material-ui/icons/HighlightOffTwoTone";
import ChangeDBData from "../ChangeDBData";
import EditIcon from "@material-ui/icons/Edit";

const config = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("auth0:id_token"),
  },
};
const GET = gql`
  query getStudentPaymentInfo($id: Int, $turma: Int) {
    person(where: { id: { _eq: $id }, deleted_at: { _is_null: true } }) {
      id
      email
      first_name
      last_name
      postal_code
      address1
      address2
      n_address
      district
      city
      country
      postal_code
      cpf
      rg
      state
      enrollments(
        where: {
          registers: { turma: { _eq: $turma }, deleted_at: { _is_null: true } }
          deleted_at: { _is_null: true }
        }
      ) {
        id
        registers(where: { deleted_at: { _is_null: true } }) {
          turmaByTurma {
            id
            name
            category_name
          }
        }
        payment_plans(where: { deleted_at: { _is_null: true } }) {
          id
          frequencia
          valor_total
          valor_parcela
          parcelas
          parcelas_pagas
          plano_ativo
          dia_vencimento
          obs
          org
          payments(
            where: {
              _or: [
                { status: { _eq: "paid" } }
                { status: { _eq: "pending" } }
                { status: { _eq: "expired" }, disabled: { _eq: false } }
              ]
              deleted_at: { _is_null: true }
            }
            order_by: { due_date: asc }
          ) {
            id
            payment_id
            due_date
            status
            value
            disabled
          }
        }
      }
    }
  }
`;
const insertPayment = gql`
  mutation insertPayment($object: payment_insert_input!) {
    insert_payment_one(
      object: $object
      on_conflict: {
        constraint: payment_payment_id_key
        update_columns: [payment_plan, person]
      }
    ) {
      id
    }
  }
`;
const hidingMutation = gql`
  mutation disableInvoice($id: Int!) {
    update_payment(
      where: { id: { _eq: $id } }
      _set: { disabled: true, status: "canceled" }
    ) {
      affected_rows
    }
  }
`;

const deleteMutation = gql`
  mutation deletePayment($id: Int!) {
    delete_payment_by_pk(id: $id) {
      id
    }
  }
`;

const createPaymentPlan = gql`
  mutation newPlan($object: payment_plan_insert_input!) {
    insert_payment_plan_one(object: $object) {
      id
    }
  }
`;

const NewInvoice = observer((props) => {
  // null id will make this an Insert component
  const params = new URLSearchParams(props.location.search);
  const id = params.get("id");
  const store = useContext(StoreContext);
  var [state, setState] = useState({
    turma: store.ui.turma.id,
    snackbar: false,
    pp_dialog: false,
    payment_plan: null,
  });

  var [paymentPlatform, setpaymentPlatform] = useState("asaas");

  const handleRequestClose = () => {
    setState({
      ...state,
      snackbar: false,
    });
  };
  const showMessage = (msg) => {
    setState({
      ...state,
      snackbar: true,
      message: msg,
    });
  };
  const translate = (label) => {
    switch (label) {
      case "bank_slip":
        return "Boleto";
        break;
      case "credit_card":
        return "Cartão";
        break;
      case "pix":
        return "PIX";
        break;
      default:
        return label;
        break;
    }
  };
  const getStudentData = () => {
    var address = aluno.address1 + " " + aluno.address2;
    var num = aluno.n_address ? aluno.n_address : address.match(/[0-9]+/);
    if (num && num[0]) num = num[0];
    var body = {
      email: aluno.email,
      name: aluno.first_name + " " + aluno.last_name,
      cpf_cnpj: aluno.cpf,
      zip_code: aluno.postal_code.match(/[0-9]+/g).join(""),
      address_street: address.replace(/,* *[0-9]+.*/, ""),
      number: num,
      district: aluno.district,
    };
    return body;
  };
  //load record:
  const [insertDB] = useMutation(insertPayment);
  const [hide] = useMutation(hidingMutation);
  const [remove] = useMutation(deleteMutation);
  const [createPlan] = useMutation(createPaymentPlan);
  const { data, error, loading, refetch } = useQuery(GET, {
    variables: {
      id: id,
      turma: store.ui.turma.id,
    },
  });
  if (loading) return <p>Carregando... </p>;
  if (error) return <p>ERROR (NewInvoice.jsx): {error.message}</p>;
  var aluno = JSON.parse(JSON.stringify(data.person[0]));
  if (!aluno) return <p>Aluno com id {id} não encontrado</p>;
  var plano = aluno.enrollments[0]
    ? aluno.enrollments[0].payment_plans[0]
    : null;

  var gap = false;
  var mesAtual = new Date().getMonth() + 2; // 05
  var anoAtual = new Date().getFullYear(); // 2022
  var somaTotal;
  var numfat = 0; //faturas ativas
  if (plano) {
    state.payment_plan = plano.id;
    numfat = plano.payments.length;

    var value = plano.payments;
    somaTotal = value
      .map((item) => item.value)
      .reduce((prev, curr) => prev + curr, 0);

    var invoicesPendings = value.filter(
      (item) =>
        item.status === "pending" &&
        item.due_date >= anoAtual + "-" + mesAtual.toString().padStart(2, "0")
    );
    invoicesPendings.map((item) => {
      if (
        plano.frequencia === "mensal" &&
        new Date(item.due_date + "T00:00:00").getFullYear() === anoAtual
      ) {
        if (mesAtual !== new Date(item.due_date + "T00:00:00").getMonth() + 1) {
          gap = true;
        } else {
          mesAtual++;
          if (mesAtual > 12) {
            mesAtual = 1;
            anoAtual++;
          }
        }
      }
    });
  }
  var pagamentos = ["bank_slip", "credit_card", "pix"];
  var faturasParaCriar = false;
  if (plano && numfat < plano.parcelas) {
    faturasParaCriar = true;
    var batch = {
      payable_with: pagamentos,
      invoices: [],
      max_installments_value: 1,
    };
    if (plano.payments.length > 0) {
      var aux_date = plano.payments[plano.payments.length - 1].due_date;
      var date = new Date(aux_date + "T12:00:00");
    } else {
      var date = new Date();
    }
    var matricula = plano.parcelas > 12 ? 1 : 0; //17 e 24 têm matrícula
    var parc_corrigido = plano.parcelas - matricula;
    for (var i = 0; i < plano.parcelas - numfat; i++) {
      if (plano.dia_vencimento) date.setDate(plano.dia_vencimento);
      date.setMonth(date.getMonth() + 1);
      batch.invoices[i] = {
        price: plano.valor_parcela ? plano.valor_parcela : 0,
        due_date: date.toISOString().split("T")[0],
        description:
          store.ui.turma.category_name +
          " - " +
          store.ui.turma.name.match(/[0-9]{4}-[0-9]{1}/) +
          " (parc " +
          (numfat + i + 1 - matricula) +
          "/" +
          parc_corrigido +
          ")",
      };
    }
  }

  const create_invoices = async (studentData, list) => {
    list.forEach(async (values) => {
      try {
        let body = {
          ...studentData,
        };
        body.due_date = values.due_date;
        body.items = [
          {
            description: values.description,
            quantity: 1,
            price_cents: values.price,
          },
        ];
        body.payable_with = values.payable_with;
        body.max_installments_value = values.max_installments_value;
        let org = "";
        if (plano && plano.org) org = "&org=" + plano.org;
        let payment_plan = plano ? plano.id : null;
        let person = plano ? null : aluno.id;
        let payment = await insertDB({
          variables: {
            object: {
              due_date: values.due_date,
              value: values.price / 100,
              payment_plan,
              person,
              description: "Aguardando sistema",
              org,
              platform: paymentPlatform,
            },
          },
        });
        body.payment_id = payment.data.insert_payment_one.id;
        body.payment_plan = payment_plan;
        await axios.post(
          functions[paymentPlatform] +
            "?action=create&turma=" +
            store.ui.turma.course +
            org,
          body,
          config
        );
      } catch (error) {
        alert("Erro: " + JSON.stringify(error));
      }
    });
    showMessage("Faturas enviadas para o sistema de pagamentos");
  };

  const hidePayment = async (fatura) => {
    if (fatura.id) {
      if (fatura.status === "pending") {
        await axios.put(
          functions[fatura.platform] +
            "?action=cancel&id=" +
            fatura.id +
            "&turma=" +
            store.ui.turma.course +
            "&org=" +
            fatura.org,
          null,
          config
        );
      }
      let res = await hide({ variables: { id: fatura.payment_id } });
      if (res.errors) showMessage("Erro: " + res.errors[0].message);
      else {
        showMessage('Fatura "removida"');
        refetch();
      }
    } else {
      await remove({ variables: { id: fatura.payment_id } });
    }
  };

  const setPlan = async (fatura, payment_plan) => {
    let object = {
      payment_id: fatura.id,
      due_date: fatura.vencimento,
      link: fatura.link,
      payment_plan,
      status: fatura.status,
      value: fatura.value,
      description: fatura.description,
    };
    let res = await insertDB({ variables: { object } });
    if (res.errors) showMessage("Erro: " + res.errors[0].message);
    else showMessage("Fatura adicionada no plano");
  };

  return (
    <>
      {plano && somaTotal !== plano.valor_total && (
        <p style={{ color: "red" }}>
          * A soma total das faturas está diferente do valor do plano de
          pagamento. (Total: R${Math.round(somaTotal)})
        </p>
      )}
      {gap === true && (
        <p style={{ color: "red" }}>
          O mês {mesAtual}/{anoAtual} está sem fatura
        </p>
      )}
      <div>
        <Formik
          onSubmit={async (values, actions) => {
            await create_invoices(getStudentData(), [values]);
            actions.setSubmitting(false);
          }}
          initialValues={{
            payable_with: pagamentos,
            description:
              store.ui.turma.category_name +
              " - " +
              store.ui.turma.name.match(/[0-9]{4}-[0-9]{1}/),
            max_installments_value: 1,
          }}
          render={({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <Paper elevation={2} style={{ padding: 30 }}>
                <h3>
                  {"Faturas para " +
                    aluno.first_name +
                    " " +
                    aluno.last_name +
                    " no curso " +
                    store.ui.turma.course}
                </h3>
                <div id="faturas">
                  <InvoiceTable
                    cpf={aluno.cpf}
                    course={store.ui.turma.course}
                    hidePayment={hidePayment}
                    setPlan={setPlan}
                    payment_plan={plano ? plano.id : null}
                    personId={aluno.id}
                  />
                </div>
                <div style={{ marginTop: 20 }}>
                  <InputLabel htmlFor="plataforma">
                    Escolha a plataforma de pagamentos
                  </InputLabel>
                  <NativeSelect
                    value={paymentPlatform}
                    onChange={(e) => {
                      setpaymentPlatform(e.target.value);
                    }}
                    inputProps={{
                      id: "plataforma",
                    }}
                  >
                    <option value="iugu">Iugu</option>
                    <option value="asaas">Asaas</option>
                  </NativeSelect>
                </div>
                {plano ? (
                  <>
                    <div style={{ background: "#f5f5f5" }}>
                      <IconButton
                        onClick={() => {
                          setState({ ...state, pp_dialog: true });
                        }}
                        style={{ float: "right" }}
                      >
                        <EditIcon />
                      </IconButton>
                      <p />
                      <h3>Plano de pagamento: {plano.frequencia}</h3>
                      <h3>
                        Total: R${plano.valor_total} <br />
                        {plano.parcelas}x de R${plano.valor_parcela} <br />
                        dia de vencimento: {plano.dia_vencimento} <br />
                        {plano.obs}
                      </h3>
                    </div>
                  </>
                ) : (
                  <>
                    <p>
                      O aluno não tem um plano de pagamento na turma
                      selecionada.
                    </p>

                    <Button
                      onClick={async () => {
                        await createPlan({
                          variables: {
                            object: {
                              enrollment: aluno.enrollments[0].id,
                              parcelas: 0,
                            },
                          },
                        });
                        refetch();
                      }}
                      color="primary"
                      variant="contained"
                    >
                      Novo plano
                    </Button>
                    <p />
                  </>
                )}
                <h4>Criar fatura:</h4>
                {"Valor: "}
                <Field
                  type="text"
                  name="price"
                  component={Input}
                  onChange={(e) => {
                    if (!e.target.value) var val = 0;
                    else
                      var val = parseInt(
                        e.target.value.match(/[0-9]+/g).join("")
                      );
                    setFieldValue("price", val);
                    val = val.toString().padStart(3, "0");
                    e.target.value =
                      "R$ " +
                      val.substring(0, val.length - 2) +
                      "," +
                      val.substring(val.length - 2);
                  }}
                />
                <br />
                <Field
                  type="date"
                  label="Vencimento"
                  name="due_date"
                  component={TextField}
                  InputLabelProps={{ shrink: true }}
                />
                <p />
                <Field
                  type="text"
                  label="Descrição"
                  name="description"
                  component={TextField}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                />
                <p />
                {"Formas de pagamento: "}
                {pagamentos.map((p) => {
                  return (
                    <>
                      <Field
                        name={p}
                        id={p}
                        component={Checkbox}
                        color="primary"
                        onChange={(e) => {
                          if (e.target.checked) {
                            values.payable_with.push(p);
                          } else {
                            var i = values.payable_with.findIndex(
                              (n) => n === p
                            );
                            if (i >= 0) values.payable_with.splice(i, 1);
                          }
                        }}
                        defaultChecked={values.payable_with.includes(p)}
                        inputProps={{ "aria-label": translate(p) }}
                      />
                      <label htmlFor={p}>{translate(p)}</label>
                    </>
                  );
                })}
                <br />
                <Field
                  type="number"
                  label="Máximo de parcelamento da fatura"
                  name="max_installments_value"
                  component={TextField}
                  InputLabelProps={{ shrink: true }}
                />
                <p />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  style={{ margin: "30px" }}
                >
                  Criar Fatura
                </Button>
              </Paper>
            </Form>
          )}
        />
      </div>
      {faturasParaCriar && (
        <div>
          <Formik
            onSubmit={async (values, actions) => {
              try {
                let invoices = values.invoices.map((invoice) => {
                  var preco = invoice.price;
                  if (typeof preco === "number") preco = preco * 100;
                  else {
                    preco = parseFloat(preco.replace(",", ".")) * 100;
                  }
                  return {
                    ...invoice,
                    payable_with: values.payable_with,
                    max_installments_value: values.max_installments_value,
                    price: preco,
                  };
                });
                create_invoices(getStudentData(), invoices);
              } catch (error) {
                showMessage(JSON.stringify(error));
              }
              actions.setSubmitting(false);
            }}
            initialValues={batch}
            render={({ values, setFieldValue, isSubmitting }) => (
              <Form>
                <Paper elevation={2} style={{ padding: 30 }}>
                  <h3>Criar faturas restantes do plano:</h3>
                  <FieldArray
                    name="invoices"
                    render={(arrayHelpers) => (
                      <div>
                        {values.invoices.map((invoice, i) => {
                          return (
                            <>
                              <div
                                style={{
                                  borderStyle: "inset",
                                  borderRadius: "20px",
                                  padding: "20px",
                                }}
                              >
                                <IconButton
                                  onClick={() => {
                                    arrayHelpers.remove(i);
                                  }}
                                  style={{ float: "right" }}
                                >
                                  <HighlightOffTwoToneIcon />
                                </IconButton>
                                {"Valor: R$"}
                                <Field
                                  type="text"
                                  name={"invoices[" + i + "].price"}
                                />
                                <br />
                                <p>
                                  <Field
                                    type="date"
                                    label="Vencimento"
                                    name={"invoices[" + i + "].due_date"}
                                    component={TextField}
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </p>
                                <p>
                                  <Field
                                    type="text"
                                    label="Descrição"
                                    name={"invoices[" + i + "].description"}
                                    component={TextField}
                                    fullWidth={true}
                                    InputLabelProps={{ shrink: true }}
                                  />
                                </p>
                              </div>
                            </>
                          );
                        })}
                        {
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            onClick={() => {
                              //
                              arrayHelpers.push({});
                            }}
                          >
                            adicionar fatura
                          </Button>
                        }
                      </div>
                    )}
                  />

                  {"Formas de pagamento: "}
                  {pagamentos.map((p) => {
                    return (
                      <>
                        <Field
                          name={p}
                          id={p}
                          component={Checkbox}
                          color="primary"
                          onChange={(e) => {
                            if (e.target.checked) {
                              values.payable_with.push(p);
                            } else {
                              var i = values.payable_with.findIndex(
                                (n) => n === p
                              );
                              if (i >= 0) values.payable_with.splice(i, 1);
                            }
                          }}
                          defaultChecked={values.payable_with.includes(p)}
                          inputProps={{ "aria-label": translate(p) }}
                        />
                        <label htmlFor={p}>{translate(p)}</label>
                      </>
                    );
                  })}
                  <br />
                  <p>
                    <Field
                      type="number"
                      label="Máximo de parcelamento da fatura"
                      name="max_installments_value"
                      component={TextField}
                      InputLabelProps={{ shrink: true }}
                    />
                  </p>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{ margin: "30px" }}
                  >
                    Criar Todas as Faturas
                  </Button>
                </Paper>
              </Form>
            )}
          />
        </div>
      )}

      <SavedSnack
        open={state.snackbar}
        msg={state.message}
        setOpen={(open) => {
          setState({
            ...state,
            snackbar: open,
          });
        }}
        onRequestClose={handleRequestClose}
      />

      <Dialog
        open={state.pp_dialog}
        onClose={() => {
          setState({ ...state, pp_dialog: false });
        }}
      >
        <div style={{ minWidth: "600px" }}>
          <ChangeDBData
            match={{
              params: {
                table: "payment_plan",
                id: state.payment_plan,
              },
            }}
            location={{
              ...props.location,
              search: "",
            }}
          />
        </div>
      </Dialog>
    </>
  );
});
export default NewInvoice;

// eslint-disable-next-line no-unused-vars
const formatBody = (body, values, plano) => {
  body.due_date = values.due_date;
  body.items = [
    {
      description: values.description,
      quantity: 1,
      price_cents: values.price,
    },
  ];
  body.payable_with = values.payable_with;
  body.max_installments_value = values.max_installments_value;
  if (plano) body.payment_plan = plano.id;
  return body;
};

const functions = {
  iugu: "/.netlify/functions/createinvoice_iugu-background",
  asaas: "/.netlify/functions/createinvoice_asaas-background",
};
