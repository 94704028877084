import React, { useContext, useState, useEffect } from "react";
import { useQuery, useMutation } from "react-apollo-hooks";
import { observer } from "mobx-react-lite";
import { StoreContext } from "../store";
import {
  Button,
  Typography,
  Paper,
  Card,
  CardContent,
  CardActions,
  Divider,
  Box,
  Container,
  Grid,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import {
  CheckCircle as CheckCircleIcon,
  Event as EventIcon,
  EventAvailable as EventAvailableIcon,
  ArrowBack as ArrowBackIcon,
  Info as InfoIcon
} from "@material-ui/icons";
import gql from "graphql-tag";
import SavedSnack from "./SavedSnack";
import "../css/AddAttendances.css";

const QUERY_MODULAR_ATTENDANCE = gql`
  query getModularAttendanceDates($turma: Int, $email: String!) {
    modular_class_schedule(
      where: { turma_id: { _eq: $turma } }
      order_by: { class_date: asc }
    ) {
      id
      class_date
    }

    modular_live_attendance(
      where: { turma_id: { _eq: $turma }, person: { email: { _eq: $email } } }
    ) {
      id
      class_date
    }

    person(where: { email: { _eq: $email } }) {
      id
    }
  }
`;

const REGISTER_ATTENDANCE_MUTATION = gql`
  mutation registerModularAttendance(
    $objects: [modular_live_attendance_insert_input!]!
  ) {
    insert_modular_live_attendance(objects: $objects) {
      affected_rows
    }
  }
`;

const ModularSelfAttendance = observer(() => {
  const store = useContext(StoreContext);
  const [snack, setSnack] = useState({ msg: "", open: false });
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [confirmCheckbox, setConfirmCheckbox] = useState(false);
  const [processingRegistration, setProcessingRegistration] = useState(false);
  
  const [localRegisteredAttendances, setLocalRegisteredAttendances] = useState([]);

  const turma = store.ui?.turma?.id;
  const email = store.email;

  const { data, error, loading } = useQuery(QUERY_MODULAR_ATTENDANCE, {
    variables: { turma: turma, email: email },
    skip: !turma || !email,
  });

  const [registerAttendance] = useMutation(REGISTER_ATTENDANCE_MUTATION);

  useEffect(() => {
    if (data && data.modular_live_attendance) {
      setLocalRegisteredAttendances(data.modular_live_attendance);
    }
  }, [data]);

  const showMsg = (msg) => {
    setSnack({ msg: msg, open: true });
  };

  if (loading)
    return (
      <Container maxWidth="md" style={{ marginTop: 40 }}>
        <Paper elevation={3} style={{ padding: 24, textAlign: "center" }}>
          <Typography variant="h5" color="textSecondary">
            Carregando dados de presença...
          </Typography>
        </Paper>
      </Container>
    );

  if (error)
    return (
      <Container maxWidth="md" style={{ marginTop: 40 }}>
        <Paper
          elevation={3}
          style={{
            padding: 24,
            textAlign: "center",
            backgroundColor: "#ffebee",
          }}
        >
          <Typography variant="h5" color="error">
            Erro ao carregar dados
          </Typography>
          <Typography variant="body1" color="error" style={{ marginTop: 16 }}>
            {error.message}
          </Typography>
        </Paper>
      </Container>
    );

  if (!data)
    return (
      <Container maxWidth="md" style={{ marginTop: 40 }}>
        <Paper elevation={3} style={{ padding: 24, textAlign: "center" }}>
          <Typography variant="h5" color="textSecondary">
            Nenhum dado encontrado
          </Typography>
        </Paper>
      </Container>
    );

  const availableDates = data.modular_class_schedule || [];
  
  const registeredDates = {};
  localRegisteredAttendances.forEach(att => {
    registeredDates[new Date(att.class_date).toISOString().split('T')[0]] = true;
  });

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const pastDates = availableDates.filter((schedule) => {
    const scheduleDate = new Date(schedule.class_date);
    scheduleDate.setHours(0, 0, 0, 0);
    return scheduleDate <= today;
  });

  const futureDates = availableDates.filter((schedule) => {
    const scheduleDate = new Date(schedule.class_date);
    scheduleDate.setHours(0, 0, 0, 0);
    return scheduleDate > today;
  });

  const formatSelectedDate = () => {
    if (!selectedDate) return "";
    const dateStr = new Date(selectedDate).toISOString().split('T')[0];
    const parts = dateStr.split('-');
    return `${parts[2]}/${parts[1]}/${parts[0]}`;
  };

  const openConfirmDialog = (classDate) => {
    setSelectedDate(classDate);
    setConfirmCheckbox(false);
    setConfirmDialogOpen(true);
  };

  const handleConfirmPresenca = async () => {
    if (!selectedDate || !confirmCheckbox || !data?.person?.[0]?.id) return;
    
    const dateStr = new Date(selectedDate).toISOString().split('T')[0];
    
    if (registeredDates[dateStr]) {
      showMsg("Presença já registrada para esta data");
      setConfirmDialogOpen(false);
      return;
    }

    setProcessingRegistration(true);

    try {
      await registerAttendance({
        variables: {
          objects: [
            {
              person_id: data.person[0].id,
              turma_id: turma,
              class_date: dateStr,
              comments: "Presença confirmada pelo aluno"
            },
          ],
        },
      });
      
      const newAttendance = {
        id: Date.now(), 
        class_date: dateStr
      };
      
      setLocalRegisteredAttendances(prev => [...prev, newAttendance]);
      showMsg("Presença registrada com sucesso!");
      
      setConfirmDialogOpen(false);
    } catch (e) {
      showMsg("Erro ao registrar presença: " + e.message);
    } finally {
      setProcessingRegistration(false);
      setSelectedDate(null);
    }
  };

  return (
    <Container maxWidth="md" style={{ marginTop: 40, marginBottom: 40 }}>
      <Paper elevation={3} style={{ borderRadius: 8, overflow: "hidden" }}>
        <Box
          bgcolor="primary.main"
          color="primary.contrastText"
          py={3}
          px={4}
          display="flex"
          alignItems="center"
        >
          <EventIcon style={{ fontSize: 36, marginRight: 16 }} />
          <Typography variant="h4" component="h3" style={{ color: "white" }}>
            Registro de Presença
          </Typography>
        </Box>

        <Box p={4}>
          <Typography
            variant="h6"
            gutterBottom
            color="textSecondary"
            align="center"
          >
            Clique no cartão da aula para registrar sua presença
          </Typography>

          {pastDates.length > 0 || futureDates.length > 0 ? (
            <>
              <Box display="flex" justifyContent="center" mb={3}>
                <Chip
                  icon={<EventAvailableIcon />}
                  label={`Total de aulas: ${availableDates.length}`}
                  color="primary"
                  variant="outlined"
                />
              </Box>

              {pastDates.length > 0 && (
                <>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    gutterBottom
                    style={{ fontWeight: "bold", marginTop: 16 }}
                  >
                    Aulas disponíveis para registro
                  </Typography>
                  <Grid container spacing={3}>
                    {pastDates.map((schedule) => {
                      const dateStr = schedule.class_date;
                      const parts = dateStr.split('-');
                      const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
                      
                      const isRegistered = registeredDates[dateStr];

                      return (
                        <Grid item xs={12} sm={6} key={schedule.id}>
                          <Card
                            elevation={3}
                            style={{
                              borderRadius: 8,
                              backgroundColor: isRegistered
                                ? "#e8f5e9"
                                : "white",
                              borderLeft: isRegistered
                                ? "4px solid #4caf50"
                                : "4px solid #2196f3",
                              transition: "all 0.3s ease",
                            }}
                          >
                            <CardContent>
                              <Box display="flex" alignItems="center">
                                {isRegistered ? (
                                  <CheckCircleIcon
                                    style={{
                                      color: "#4caf50",
                                      marginRight: 12,
                                      fontSize: 24,
                                    }}
                                  />
                                ) : (
                                  <EventIcon
                                    style={{
                                      color: "#2196f3",
                                      marginRight: 12,
                                      fontSize: 24,
                                    }}
                                  />
                                )}
                                <Typography variant="h6">
                                  {formattedDate}
                                </Typography>
                              </Box>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                style={{ marginTop: 8 }}
                              >
                                Aula ao vivo do curso modular
                              </Typography>
                            </CardContent>
                            <CardActions>
                              <Button
                                fullWidth
                                variant={
                                  isRegistered ? "outlined" : "contained"
                                }
                                color={isRegistered ? "default" : "primary"}
                                onClick={() =>
                                  isRegistered ? null : openConfirmDialog(schedule.class_date)
                                }
                                disabled={isRegistered}
                                size="large"
                                style={{ borderRadius: 20 }}
                              >
                                {isRegistered
                                  ? "Presença Registrada"
                                  : "Registrar Presença"}
                              </Button>
                            </CardActions>
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>
                </>
              )}

              {futureDates.length > 0 && (
                <>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    gutterBottom
                    style={{ fontWeight: "bold", marginTop: 24 }}
                  >
                    Próximas aulas
                  </Typography>
                  <Grid container spacing={3}>
                    {futureDates.map((schedule) => {
                      const dateStr = schedule.class_date;
                      const parts = dateStr.split('-');
                      const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;

                      return (
                        <Grid item xs={12} sm={6} key={schedule.id}>
                          <Card
                            elevation={2}
                            style={{
                              borderRadius: 8,
                              backgroundColor: "#f5f5f5",
                              borderLeft: "4px solid #9e9e9e",
                              opacity: 0.8,
                            }}
                          >
                            <CardContent>
                              <Box display="flex" alignItems="center">
                                <EventIcon
                                  style={{
                                    color: "#9e9e9e",
                                    marginRight: 12,
                                    fontSize: 24,
                                  }}
                                />
                                <Typography variant="h6" color="textSecondary">
                                  {formattedDate}
                                </Typography>
                              </Box>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                style={{ marginTop: 8 }}
                              >
                                Aula ao vivo (futura)
                              </Typography>
                            </CardContent>
                            <CardActions>
                              <Button
                                fullWidth
                                variant="outlined"
                                disabled
                                size="large"
                                style={{ borderRadius: 20 }}
                              >
                                Disponível no dia da aula
                              </Button>
                            </CardActions>
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>
                </>
              )}
            </>
          ) : (
            <Box
              bgcolor="#f5f5f5"
              p={4}
              borderRadius={8}
              textAlign="center"
              mt={3}
            >
              <Typography variant="h6" color="textSecondary">
                Nenhuma aula disponível para registro no momento.
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginTop: 8 }}
              >
                Verifique novamente mais tarde ou entre em contato com o
                suporte.
              </Typography>
            </Box>
          )}

          <Divider style={{ margin: "32px 0 24px" }} />

          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            paragraph
          >
            A presença só pode ser registrada no dia da aula ou após a mesma.
            Para problemas com o registro, entre em contato com o suporte.
          </Typography>

          <Box display="flex" justifyContent="center" mt={3}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => window.history.back()}
              startIcon={<ArrowBackIcon />}
              size="large"
            >
              Voltar
            </Button>
          </Box>
        </Box>
      </Paper>

      <Dialog
        open={confirmDialogOpen}
        onClose={() => !processingRegistration && setConfirmDialogOpen(false)}
        aria-labelledby="confirm-attendance-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="confirm-attendance-dialog-title">
          Confirmar Registro de Presença
        </DialogTitle>
        <DialogContent>
          <Box 
            mb={2} 
            p={2} 
            bgcolor="#e3f2fd" 
            borderRadius={4} 
            display="flex" 
            alignItems="flex-start"
          >
            <InfoIcon style={{ color: '#2196f3', marginRight: 12, marginTop: 2 }} />
            <DialogContentText>
              Você está prestes a registrar presença para a aula do dia <strong>{formatSelectedDate()}</strong>. 
              Esta ação confirmará que você participou desta aula.
            </DialogContentText>
          </Box>
          
          <DialogContentText style={{ marginBottom: 16 }}>
            Por favor, confirme que você realmente participou desta aula e está ciente de que:
          </DialogContentText>
          
          <Box mb={2}>
            <Typography variant="body2" style={{ marginBottom: 8 }}>
              • O registro de presença é uma declaração oficial de participação
            </Typography>
            <Typography variant="body2">
              • Este registro não pode ser desfeito posteriormente
            </Typography>
          </Box>
          
          <FormControlLabel
            control={
              <Checkbox
                checked={confirmCheckbox}
                onChange={(e) => setConfirmCheckbox(e.target.checked)}
                color="primary"
              />
            }
            label="Confirmo que participei desta aula e estou ciente das condições acima"
            style={{ marginTop: 16 }}
          />
        </DialogContent>
        <DialogActions style={{ padding: 16 }}>
          <Button 
            onClick={() => setConfirmDialogOpen(false)} 
            color="default"
            disabled={processingRegistration}
          >
            Cancelar
          </Button>
          <Button 
            onClick={handleConfirmPresenca} 
            color="primary" 
            variant="contained"
            disabled={!confirmCheckbox || processingRegistration}
          >
            {processingRegistration ? "Processando..." : "Confirmar Presença"}
          </Button>
        </DialogActions>
      </Dialog>

      <SavedSnack
        open={snack.open}
        msg={snack.msg}
        setOpen={(o) => setSnack({ ...snack, open: o })}
      />
    </Container>
  );
});

export default ModularSelfAttendance;
