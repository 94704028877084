import React, { Fragment, useContext } from "react";
import { observer } from "mobx-react-lite";
import { store, StoreContext } from "../store";
import { useMutation, useQuery } from "react-apollo-hooks";
import { Link as RouterLink } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Typography, Tooltip, Divider } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import EditOutlinedIcon from "@material-ui/icons/Edit";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { History } from "history";

import {
  getAllActivities,
  ActivityInterface,
  lessonInterface,
  getAllACtivitiesInterface,
  removeActivity,
  removeLesson,
} from "../queriesAndMutations";
import LessonFeedback from "./LessonFeedback";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: "inline",
    },
  });

interface ClassesInterface {
  root: string;
  inline: string;
}

interface ActivityProps {
  activity: ActivityInterface;
  lesson: lessonInterface;
  classes: ClassesInterface;
  history: History;
  admin: Boolean;
}

interface ClassesInterface {
  [key: string]: string;
}

const Activity = ({
  classes,
  activity,
  lesson,
  history,
  admin,
}: ActivityProps) => {
  const { title, type } = activity;
  const completion = activity.activity_completions[0]
    ? activity.activity_completions[0]
    : { comment: "", completed: false, date_done: "" };
  const icon = completion.completed ? (
    <CheckBoxIcon />
  ) : (
    <CheckBoxOutlineBlankIcon />
  );
  const MyLink = (props: any) => (
    <RouterLink
      to={{ pathname: "/viewActivity", state: { activity, lesson } }}
      {...props}
    />
  );
  const [deleteActivity] = useMutation(removeActivity);
  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <ListItem
          button
          component={MyLink}
          alignItems="flex-start"
          disabled={!activity.available}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText
            primary={type}
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  className={classes.inline}
                  color="textPrimary"
                >
                  {title}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        {admin && (
          <div className="flex-col">
            <Tooltip title="edita">
              <IconButton
                onClick={() => {
                  history.push(`/editactivity/${activity.id}`);
                }}
                style={{
                  color: "#66BB6A",
                }}
              >
                <EditOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="remover">
              <IconButton
                onClick={async () => {
                  if (window.confirm("Excluir?")) {
                    await deleteActivity({ variables: { id: activity.id } });
                    if (store.ui.refetch) store.ui.refetch();
                  }
                }}
                style={{
                  color: "#FF0000",
                }}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
      <Divider />
    </Fragment>
  );
};

interface ListActivitiesProps {
  classes?: ClassesInterface;
  history: History;
}

const ListActivities = observer(({ history, classes }: ListActivitiesProps) => {
  const store = useContext(StoreContext);
  const email = store.email;
  const turmaId = store.ui.turma.id;
  const [deleteLesson] = useMutation(removeLesson);

  const { data, error, loading, refetch } = useQuery<getAllACtivitiesInterface>(
    getAllActivities,
    {
      variables: { email, turmaId },
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
    }
  );

  if (loading) return <p>Carregando... </p>;
  if (error) return <p>ERROR (ListActivities.js): {error.message}</p>;

  const lesson = data!.lesson;
  if (lesson.length <= 0) return <p>Lições ainda não carregadas...</p>;

  store.ui.refetch = refetch;

  const firstAvailableIndex =
    lesson
      .map((l, index) => ({ available: l.available, index }))
      .filter((l) => l.available || store.admin)
      .shift()?.index ?? 0;

  const lastAvailableIndex =
    lesson
      .map((l, index) => ({ available: l.available, index }))
      .filter((l) => l.available || store.admin)
      .pop()?.index ?? 0;

  if (!store.ui.lessonDirty) {
    store.ui.setLessonPos(lastAvailableIndex);
    store.ui.lessonDirty = true;
  }

  if (!lesson[store.ui.lessonPos].available && !store.admin) {
    store.ui.setLessonPos(lastAvailableIndex);
  }

  return (
    <div>
      {(lesson[store.ui.lessonPos].available || store.admin) && (
        <>
          <LessonFeedback
            lessonId={lesson[store.ui.lessonPos].id}
            key={lesson[store.ui.lessonPos].id}
            personId={store.personId}
            turmaId={store.ui.turma.id}
          />
          <Typography style={{ fontSize: 20, marginBottom: 8 }}>
            {lesson[store.ui.lessonPos].disciplinaByDisciplina
              ? `Disciplina: ${
                  lesson[store.ui.lessonPos].disciplinaByDisciplina.name
                }`
              : ""}
          </Typography>
          <div
            style={{
              opacity:
                !lesson[store.ui.lessonPos].available && store.admin ? 0.5 : 1,
              backgroundColor:
                !lesson[store.ui.lessonPos].available && store.admin
                  ? "#f5f5f5"
                  : "transparent",
              paddingTop: "4px",
              paddingBottom: "4px",
              borderRadius: "4px",
            }}
          >
            <Typography variant="h5">
              {!store.ui.turma.course.includes("Rodas") &&
              !store.ui.turma.course.includes("Supervisão")
                ? "Lição " + lesson[store.ui.lessonPos].number.toString() + ": "
                : "Vídeo " +
                  lesson[store.ui.lessonPos].number.toString() +
                  ": "}
              {lesson[store.ui.lessonPos].title}
              {store.admin && (
                <>
                  {!lesson[store.ui.lessonPos].available && (
                    <Typography
                      variant="caption"
                      style={{
                        color: "#666",
                        marginLeft: "10px",
                        fontStyle: "italic",
                      }}
                    >
                      (Lição indisponível para alunos)
                    </Typography>
                  )}
                  <Tooltip title="editar">
                    <IconButton
                      onClick={() => {
                        history.push(
                          `/editDB/lesson/${lesson[store.ui.lessonPos].id}`
                        );
                      }}
                      style={{
                        color: "#66BB6A",
                      }}
                    >
                      <EditOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="remover">
                    <IconButton
                      onClick={async () => {
                        if (window.confirm("Excluir?")) {
                          await deleteLesson({
                            variables: { id: lesson[store.ui.lessonPos].id },
                          });
                          if (store.ui.refetch) store.ui.refetch();
                        }
                      }}
                      style={{
                        color: "#FF0000",
                      }}
                    >
                      <DeleteOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Typography>
          </div>
          <Typography variant="body1">
            com: {lesson[store.ui.lessonPos].professor}
          </Typography>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginTop: "10px",
              marginBottom: "6px",
              padding: "6px 10px",
              backgroundColor: "#f5f5f5",
              borderRadius: "4px",
              boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
            }}
          >
            <Typography
              variant="body2"
              style={{
                color:
                  store.ui.lessonPos > firstAvailableIndex ? "#5a5a5a" : "#aaa",
                fontWeight: 500,
                marginRight: 4,
              }}
            >
              Anterior
            </Typography>

            <IconButton
              disabled={store.ui.lessonPos <= firstAvailableIndex}
              onClick={() =>
                store.ui.lessonPos > firstAvailableIndex
                  ? store.ui.setLessonPos(store.ui.lessonPos - 1)
                  : null
              }
              style={{
                color:
                  store.ui.lessonPos > firstAvailableIndex ? "#801819" : "#ddd",
                padding: "6px",
                margin: "0 4px",
              }}
            >
              <ArrowLeftIcon />
            </IconButton>

            <Typography
              variant="body2"
              style={{ margin: "0 12px", fontWeight: 500 }}
            >
              {lesson[store.ui.lessonPos].number}/
              {lesson[lastAvailableIndex].number}
            </Typography>

            <IconButton
              disabled={store.ui.lessonPos >= lastAvailableIndex}
              onClick={() =>
                store.ui.lessonPos < lastAvailableIndex
                  ? store.ui.setLessonPos(store.ui.lessonPos + 1)
                  : null
              }
              style={{
                color:
                  store.ui.lessonPos < lastAvailableIndex ? "#801819" : "#ddd",
                padding: "6px",
                margin: "0 4px",
              }}
            >
              <ArrowRightIcon />
            </IconButton>

            <Typography
              variant="body2"
              style={{
                color:
                  store.ui.lessonPos < lastAvailableIndex ? "#5a5a5a" : "#aaa",
                fontWeight: 500,
                marginLeft: 4,
              }}
            >
              Próxima
            </Typography>

            {store.admin && (
              <Tooltip title="adicionar lição">
                <IconButton
                  onClick={() => {
                    history.push(`/editDB/lesson?turma=${turmaId}`);
                  }}
                  style={{
                    color: "#66BB6A",
                    marginLeft: "auto",
                  }}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
          
          {(store.ui.lessonPos > firstAvailableIndex || store.ui.lessonPos < lastAvailableIndex) && (
            <div style={{ 
              backgroundColor: '#FFF8E1', 
              padding: '4px 12px', 
              borderRadius: '4px',
              marginBottom: '10px',
              fontSize: '13px',
              color: '#5D4037',
              display: 'flex',
              alignItems: 'center',
              maxWidth: '500px'
            }}>
              <div style={{ 
                width: '6px', 
                height: '6px', 
                backgroundColor: '#FFA000', 
                borderRadius: '50%',
                marginRight: '8px' 
              }}></div>
              <span>
                {store.ui.lessonPos > firstAvailableIndex && store.ui.lessonPos < lastAvailableIndex
                  ? 'Existem lições disponíveis antes e depois desta'
                  : store.ui.lessonPos > firstAvailableIndex
                    ? 'Existem lições anteriores disponíveis'
                    : 'Existem mais lições disponíveis adiante'}
              </span>
            </div>
          )}

          <div style={{ 
            marginTop: "10px",
            maxWidth: "360px" 
          }}>
            <Typography
              variant="body1"
              style={{ 
                marginBottom: "6px", 
                fontWeight: 500, 
                color: "#555",
                fontSize: "15px",
                borderBottom: "1px solid #eee",
                paddingBottom: "4px"
              }}
            >
              Atividades
            </Typography>

            <div
              style={{
                borderRadius: "2px",
                overflow: "hidden",
                border: "none"
              }}
            >
              {lesson[store.ui.lessonPos].activities.map((activity, index) => (
                <div
                  key={activity.id}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#fff" : "#f8f8f8",
                    borderBottom: "1px solid #eee"
                  }}
                >
                  <Activity
                    activity={activity}
                    lesson={lesson[store.ui.lessonPos]}
                    classes={classes!}
                    history={history}
                    admin={store.admin}
                  />
                </div>
              ))}
            </div>

            {store.admin && (
              <div style={{ textAlign: "right", marginTop: "4px" }}>
                <Tooltip title="adicionar atividade">
                  <IconButton
                    size="small"
                    onClick={() => {
                      history.push(
                        `/editactivity?lesson=${lesson[store.ui.lessonPos].id}`
                      );
                    }}
                    style={{
                      color: "#66BB6A",
                      padding: "6px"
                    }}
                  >
                    <AddCircleOutlineIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
        </>
      )}
      {!lesson[store.ui.lessonPos].available && !store.admin && (
        <Typography variant="body1">
          Esta lição não está disponível no momento.
        </Typography>
      )}
    </div>
  );
});

export default withStyles(styles)(ListActivities);
